var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.width)?_c('div',{staticStyle:{"position":"relative"},style:({ width: (_vm.width + "px") })},[(_vm.height)?_c('div',{staticStyle:{"position":"absolute"},style:({ width: (_vm.width + "px"), height: (_vm.height + "px"), padding: _vm.type==='phone' ? '5px' : _vm.type === 'totem' ? ((_vm.calcSize(85)) + "px 0 0 " + (_vm.calcSize(190)) + "px") : ((_vm.calcSize(40)) + "px 0 0 " + (_vm.calcSize(252)) + "px") })},[_c('iframe',{style:({
              borderRadius: _vm.type === 'phone' ? "7%" : '0',
              
              width: ((_vm.originalSize.fw) + "px"),
              height: ((_vm.originalSize.fh) + "px"),
              '-ms-zoom': !_vm.iframeScale ? null : _vm.iframeScale,
              '-moz-transform': !_vm.iframeScale ? null : ("scale(" + _vm.iframeScale + ")"), 
              '-moz-transform-origin': !_vm.iframeScale ? null : '0 0',
              '-o-transform': !_vm.iframeScale ? null : ("scale(" + _vm.iframeScale + ")"),
              '-o-transform-origin': !_vm.iframeScale ? null : '0 0',
              '-webkit-transform': !_vm.iframeScale ? null : ("scale(" + _vm.iframeScale + ")"),
              '-webkit-transform-origin': !_vm.iframeScale ? null : '0 0',
            }),attrs:{"src":_vm.url}})]):_vm._e(),_c('div',{staticStyle:{"pointer-events":"none","position":"absolute"},style:({ width: (_vm.width + "px") })},[_c('v-img',{attrs:{"src":_vm.frame}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }