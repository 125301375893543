<template>
  <div style="padding:20px;background-color: #fafafb;min-height: calc(100vh);">
  <div style="padding:10px;">
    <v-row :key="key">
      <v-col
        cols="12"
        sm="12"
        md="3"
      >
        <v-card
          style="text-align: center;padding: 50px 0;"
        >
          <v-img
            v-show="false"
            v-if="qrImage && qrImage.length > 0"
            :src="qrImage"
            :lazy-src="qrImage"
            aspect-ratio="1"
            class="grey lighten-2"
            width="100%"
            height="auto"
          />
          <div v-if="svg" v-html="svg" />
          <a :href="`https:${config.domain}.dtouch.es/#/`">{{ `https://${config.domain}.dtouch.es/#/`}}</a>
          <br/>
          <br/>
          <v-icon color="primary">mdi-download</v-icon>
          <a href="#" @click="downloadSVG" class="link-download btn btn-primary btn-sm" style="text-decoration: none;">{{ $t('share.downloadSVG', locale).toUpperCase() }}</a>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="!config.withTotem ? 2 : 4"
        id="phone"
      >
        <center>
          <preview
            type="phone"
            :domain="config.domain"
            :width="getSize('phone')"
          />
        </center>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="!config.withTotem ? 7 : 5"
        id="totem-desktop"
      >
        <center>
          <preview
            :type="config.withTotem ? 'totem' : 'desktop'"
            :domain="config.domain"
            :width="getSize(config.withTotem ? 'totem' : 'desktop')"
          />
        </center>
      </v-col>
    </v-row>
  </div>
  </div>
</template>
<script>
import QRCode from 'qrcode'
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import Preview from './Preview'
export default {
  components: {
    Preview,
  },
  data: () => ({
    key: null,
    qrImage: null,
    config: { previewData: [] },
    previews: [
      {
        id: 'preview-1',
        md: 2,
        type: 'phone',
      },
      {
        id: 'preview-2',
        md: 3,
        type: 'totem',
      },
      {
        id: 'preview-3',
        md: 7,
        type: 'desktop',
      },
    ]
  }),
  computed: {
    ...mapState('app',['locale', 'dtouchWorkspace']),
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    isMobile () {
      return utils.isMobile()
    },
  },
  watch: {
    locale () {
    },
    dtouchWorkspace () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    async handleGetData () {
      if (!this.workspaceID) return
      api.getItem ('dtouch', `v1/private/workspaces/`, `${this.workspaceID}/dashboard-config`)
        .then(response => {
          this.config = {
            url: `https://${response.Domain}.dtouch.es/#/`,
            domain: response.Domain,
            withTotem: response.Config ? true : false
          }
          this.key = new Date().toISOString()
          this.handleCreateQR()
        })
    },
    getSize (v) {  
      let aux = document.getElementById(v === 'phone' ? v : 'totem-desktop')
      aux = aux ? aux.offsetWidth : null
      if (!aux) return
      if (v === 'desktop') return aux - 40
      if (v === 'totem') return aux > 500 ? 500 : aux
      return aux > 326 ? 326 -20 : aux - 20
    },
    handleCreateQR () {
      QRCode.toDataURL(this.config.url, {
        type: 'image/png',
        width: 344,
        color: {
          dark: '#000000',  // Blue dots
          light: '#FFFFFF'
        }
      })
      .then(url => {
        this.qrImage = url
      })
      .catch(err => {
        console.error(err)
      })

      QRCode.toString(this.config.url, {
        type: 'image/svg',
        color: {
          dark: '#000',
          light: 'red'
        }
      })
      .then(url => {
        this.svg = url.replace('<svg', '<svg id="dynamicIllustration" ')
      })
      .catch(err => {
        console.error(err)
      })
    },
    downloadSVG (evt) {
      const svgContent = document.getElementById("dynamicIllustration").outerHTML,
        blob = new Blob([svgContent], {
          type: "image/svg+xml"
        }),
        url = window.URL.createObjectURL(blob),
        link = evt.target;

      link.target = "_blank"
      link.download = `qr_${this.config.domain}.svg`
      link.href = url
    },
  }
}
</script>
<style lang="postcss" scoped>
  .table-main {
    width: 100%;
  }
  .table-main td {
    vertical-align: top;
  }
  .table-main.body {
    width: auto;
  }
  .qrcolumn {
    width: 400px;
    padding: 0 5px 0px 20px;
  }
</style>

