<template>
  <div 
    v-if="width"
    style="position: relative;"
    :style="{ width: `${width}px` }"
  >
          <div
            v-if="height"
            style="position: absolute;"
            :style="{ width: `${width}px`, height: `${height}px`, padding: type==='phone' ? '5px' : type === 'totem' ? `${calcSize(85)}px 0 0 ${calcSize(190)}px` : `${calcSize(40)}px 0 0 ${calcSize(252)}px` }"
          >
            <iframe
              :src="url"
              :style="{
                borderRadius: type === 'phone' ? `7%` : '0',
                
                width: `${originalSize.fw}px`,
                height: `${originalSize.fh}px`,
                '-ms-zoom': !iframeScale ? null : iframeScale,
                '-moz-transform': !iframeScale ? null : `scale(${iframeScale})`, 
                '-moz-transform-origin': !iframeScale ? null : '0 0',
                '-o-transform': !iframeScale ? null : `scale(${iframeScale})`,
                '-o-transform-origin': !iframeScale ? null : '0 0',
                '-webkit-transform': !iframeScale ? null : `scale(${iframeScale})`,
                '-webkit-transform-origin': !iframeScale ? null : '0 0',
              }"
            />
          </div>
          <div
            style="pointer-events: none;position: absolute;"
            :style="{ width: `${width}px` }"
          >
            <v-img
              :src="frame"
            />
          </div>
  </div>
</template>
<script>
import phone from '@/assets/preview/phoneBGfafafb.png'
import totem from '@/assets/preview/totem.png'
import desktop from '@/assets/preview/desktop.png'
export default {
  props: {
    width: {
      type: Number,
      default: null,
    },
    domain: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    iframeScale: null,
    scale: null,
    height: null,
  }),
  computed: {
    originalSize () {
      return this.type === 'phone' ?
        {
          w: 785,
          h: 1617,
          fw: 375,
          fh: 790,
        }
        : this.type === 'totem' ? {
          w: 1329,
          h: 2417,
          fw: 1080,
          fh: 1920,
        }
        : this.type === 'desktop' ? {
          w: 2761,
          h: 1825,
          fw: 1920,
          fh: 1080,
        }
        : {
          w: 1617,
          h: 785,
        }
    },
    url () {
      return this.type === 'phone' ? `https://${this.domain}.dtouch.es/#/?fm=true`
        : this.type === 'desktop' ? `https://${this.domain}.dtouch.es/#/`
        : `https://${this.domain}.dtouch.es/#/totem?fullscreen=true`
    },
    frame () {
      return this.type === 'phone' ? phone : this.type === 'totem' ? totem : desktop
    },
  },
  watch: {
    width () {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.scale = this.width / this.originalSize.w
      const aux = this.type === 'phone' ? this.calcSize(30) : this.type === 'totem' ? this.calcSize(375) : this.calcSize(490)
      this.iframeScale = (this.width-aux) / this.originalSize.fw// (this.width) / (this.originalSize.w - aux)
      this.height = this.calcSize(this.originalSize.h)
    },
    calcSize (v) {
      return v * this.scale
    },
  },
}
</script>

